// ** React Imports
import { Fragment, useEffect, useState } from "react";
// ** Reactstrap Imports
import { Button, ButtonGroup, Row } from "reactstrap";
import moment from "moment";
import MonthsPicker from "./ReactDatePicker/MonthsPicker";
import QuarterPicker from "./ReactDatePicker/QuarterPickers";
import YearsPicker from "./ReactDatePicker/YearsPicker";
import RangePicker from "./ReactDatePicker/RangePicker";
import DayPicker from "./ReactDatePicker/DayPicker";
import { Case, Switch } from "react-if";
import { humanText } from "@src/Services/Helpers/Actions";
import HalfYearDatePicker from "./ReactDatePicker/SemiAnnualPicker";

const PeriodicSelector = ({
  fullWidth = false,
  autoApply = false,
  defaultSelect = "all-time",
  allowedPeriods = ['day', 'month', 'quarter', 'semi-annual' , 'year', 'range'],
  defaultFrom = undefined,
  defaultTo = undefined,
  handleDateChange,
  hasAllTime = true,
  responsiveSize = 767
}) => {
  // ** States
  const [rSelected, setRSelected] = useState(defaultSelect);
  const [btnGroupVertical, setBtnGroupVertical] = useState(false);
  const [customClasses, setCustomClasses] = useState(fullWidth ? "w-100" : "");

  const getToday = () => {
    let startDate = moment().startOf("day").format("YYYY-MM-DD"); //start on monday
    let endDate = moment().endOf("day").format("YYYY-MM-DD"); // ends on saturday
    return { startDate, endDate };
  };

  const getWeekDays = () => {
    let startDate = moment().startOf("isoweek").format("YYYY-MM-DD"); //start on monday
    let endDate = moment().endOf("isoweek").format("YYYY-MM-DD"); // ends on saturday
    return { startDate, endDate };
  };

  const getQuarterDays = (quarter = moment().quarter()) => {
    const startDate = moment().quarter(quarter).startOf("quarter").format("YYYY-MM-DD");
    const endDate = moment().quarter(quarter).endOf("quarter").format("YYYY-MM-DD");
    return { startDate, endDate };
  };

  const getMonthDays = (month = moment().month()) => {
    const startDate = moment().month(month).startOf("month").format("YYYY-MM-DD");
    const endDate = moment().month(month).endOf("month").format("YYYY-MM-DD");
    return { startDate, endDate };
  };

  const getYearDays = (year = moment().year()) => {
    const startDate = moment().year(year).startOf("year").format("YYYY-MM-DD");
    const endDate = moment().year(year).endOf("year").format("YYYY-MM-DD");
    return { startDate, endDate };
  };

  const getDatesData = (selected) => {
    let data = {
      message: `${moment().format("MMMM")}, ${moment().year()}`,
      dateFrom: moment().startOf("month").format("YYYY-MM-DD"),
      dateTo: moment().endOf("month").format("YYYY-MM-DD")
    };
    let days = {};
    switch (selected) {
      case "day":
        days = getToday();
        data.message = `${moment().format("LL")}`;
        //data.message = `${moment().format("DD,MMMM"), moment().year()}`;
        data.dateFrom = moment().startOf("day").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("day").format("YYYY-MM-DD");
        break;
      case "week":
        days = getWeekDays();
        data.message = `Week ${moment().week()}, ${moment().year()}`;
        data.dateFrom = moment().startOf("week").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("week").format("YYYY-MM-DD");
        break;
      case "month":
        days = getMonthDays();
        data.message = `${moment().format("MMMM")}, ${moment().year()}`;
        data.dateFrom = moment().startOf("month").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "quarter":
        days = getQuarterDays();
        data.message = `${moment().format("Qo")} Quarter, ${moment().year()}`;
        data.dateFrom = moment().startOf("quarter").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("quarter").format("YYYY-MM-DD");
        break;
      case "year":
        days = getYearDays();
        data.message = `Year ${moment().format("YYYY")}`;
        data.dateFrom = moment().startOf("year").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("year").format("YYYY-MM-DD");
        break;
      case "range":
        data.message = "Custom Date";
        data.dateFrom = moment().startOf("day").format("YYYY-MM-DD");
        data.dateTo = moment().endOf("day").format("YYYY-MM-DD");
        break;
      default:
      case "all-time":
        data.dateFrom = defaultFrom;
        data.dateTo = defaultTo;
        break;
    }
    return data;
  };

  const periodeHandler = (selected) => {
    let { dateFrom, dateTo } = getDatesData(selected);
    setRSelected(selected);
    if (selected) {
      handleDateChange([dateFrom, dateTo], selected);
    }
  };

  const resizeWindow = () => {
    const windowWidth = window.innerWidth;
    const isFullWidth = fullWidth || windowWidth < responsiveSize;
    setBtnGroupVertical(windowWidth < 541);
    setCustomClasses(( isFullWidth ) && 'w-100');
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div className="pixel-periodic-selector">
      <div className="d-flex justify-content-center no-grow w-100">
        <ButtonGroup
          className={`${rSelected === defaultSelect ? "" : "mb-1"} ${customClasses}`}
          style={{ minWidth: "70%" }}
          vertical={btnGroupVertical}
        >
          {
            hasAllTime && 
            <Button
              color="primary"
              onClick={() => periodeHandler('all-time')}
              active={rSelected === 'all-time'}
              outline
            >
              All Time
            </Button>
          }
          {
            allowedPeriods?.map(period =>(
              <Button
                color="primary"
                key={period}
                onClick={() => periodeHandler(period)}
                active={rSelected === period}
                outline
              >
                {humanText(period)}
              </Button>
            ))
          }
        </ButtonGroup>
      </div>
      <div className="custom-flex-row w-100 align-items-center justify-content-center mx-auto fs-5">
        <Switch>
          <Case condition={rSelected === "day"}>
            <DayPicker
              name="day"
              id="day"
              className="text-center fs-3 mt-1"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "day")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
          <Case condition={rSelected === "month"}>
            <MonthsPicker
              name="month"
              id="month"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "month")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
          <Case condition={rSelected === "quarter"}>
            <QuarterPicker
              name="quarter"
              id="quarter"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "quarter")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
          <Case condition={rSelected === "semi-annual"}>
            <HalfYearDatePicker
              name="semi-annual"
              id="semi-annual"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "semi-annual")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
          <Case condition={rSelected === "year"}>
            <YearsPicker
              name="year"
              id="year"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "year")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
          <Case condition={rSelected === "range"}>
            <RangePicker
              name="range"
              id="range"
              className="text-center fs-3"
              maxDate={moment().toDate()}
              handleDateChange={date => handleDateChange(date, "range")}
              autoApply={autoApply}
              hasDefaultDate
            />
          </Case>
        </Switch>
      </div>
    </div>
  );
};

export default PeriodicSelector;
