import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Label, Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const getYearHalf = (date) => (moment(date).quarter() <= 2) ? 1 : 2;
const getYearHalfFormat = (date) => (moment(date).quarter() <= 2) ? `yyyy, '1st Half'` : `yyyy, '2nd Half'`;

const HalfYearDatePicker = ({
  name,
  id,
  label,
  autoClose = false,
  autoApply = false,
  className = '',
  handleDateChange = () => {},
  useApply = true,
  hasDefaultDate = false,
  ...props
}) => {
  //
  let datepickerRef = useRef(null);
  const [picker, setPicker] = useState(() =>  hasDefaultDate ? new Date() : undefined);
  const [format, setFormat] = useState(() => getYearHalfFormat(new Date()));

  const applyDatepicker = useCallback(() => {
    if (!picker) return;

    const half = getYearHalf(picker);
    const year = moment(picker).year();
    const startOfYearHalf = moment([year, half === 1 ? 0 : 6, 1]).format("YYYY-MM-DD");
    const endOfYearHalf = moment(startOfYearHalf).add(1, 'quarter').endOf('quarter').format("YYYY-MM-DD");
    //
    handleDateChange([startOfYearHalf, endOfYearHalf]);
    //
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  }, [picker, handleDateChange]);

  const closeDatepicker = useCallback(() => {
    setPicker(new Date());
    if (datepickerRef.current) {
      datepickerRef.current.setOpen(false);
    }
  }, []);

  const onChange = useCallback((date) => {
    setPicker(date);
    setFormat(`${getYearHalfFormat(date)}`);
  }, []);

  const renderQuarterContent = useCallback((quarter) => {
    if (quarter === 1) return "H1";
    if (quarter === 3) return "H2";
    return null;
  }, []);


  useEffect(() => {
    if (autoApply && picker) {
      applyDatepicker();
    }
  }, [picker, autoApply]);

  if (!picker || !format) {
    return null;
  }

  return (
    <div className="cursor-pointer">
      {label && <Label className="form-label" htmlFor={id}>{label}</Label>}
      <DatePicker
        {...props}
        selected={picker}
        id={id}
        name={name}
        className={`form-control cursor-pointer ${className}`}
        onChange={onChange}
        dateFormat={format}
        calendarClassName="semi-annual-picker-calendar"
        showQuarterYearPicker
        renderQuarterContent={renderQuarterContent}
        shouldCloseOnSelect={autoClose}
        ref={datepickerRef}
        onClickOutside={() => {
          if (datepickerRef.current) {
            datepickerRef.current.setFocus(true);
          }
        }}
        >
        {!autoApply && (
          <>
            <div className="clearfix"></div>
            <div className="custom-flex-row justify-content-between align-items-center w-100">
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 apply-dp"
                onClick={() => {
                  closeDatepicker();
                }}>
                Cancel
              </Button.Ripple>
              <Button.Ripple
                color="relief-primary"
                className="flex-2-col rounded-0 close-dp"
                onClick={() => applyDatepicker()}>
                Apply
              </Button.Ripple>
            </div>
          </>
        )}
      </DatePicker>
    </div>
  );
};

export default HalfYearDatePicker;