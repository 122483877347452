// ** React Imports
import { forwardRef, useEffect, useRef, useState } from "react";

// ** Third Party Components
import Proptypes from "prop-types";
import classnames from "classnames";

// ** Reactstrap Imports
import { Badge } from "reactstrap";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { getRandomKey } from "@src/Services/Helpers/Actions";

const Avatar = (
  {
    img,
    size,
    icon,
    color = "primary",
    status,
    badgeUp,
    content,
    Tag = 'div',
    initials = true,
    imgWidth,
    className,
    badgeText,
    imgHeight,
    badgeColor,
    imgClassName,
    contentStyles,
    imgOnError = undefined,
    withPreview = false ,
    key = getRandomKey() // to redner component
  }
) => {

  const [error, setError] = useState(false);
  const handleError = (e) => {
    if (imgOnError) {
      e.target.src = imgOnError;
    } else {
      setError(true);
    }
  };
  // ** Function to extract initials from content
  const getInitials = (str) => {
    const results = [];
    const wordArray = str?.split(" ") || [];
    wordArray.forEach((e) => {
      results.push(e[0]);
    });
    return results.join("")?.slice(0, 2);
  };

  useEffect(() => {
    if (img || imgOnError) {
      setError(false);
    }
  }, [img, imgOnError]);

return (
    <Tag
      className={classnames(
        "avatar justify-content-center align-items-center text-uppercase me-25",
        {
          [className]: className,
          [`bg-${color}`]: error || !img ? color : '',
          [`avatar-${size}`]: size
        }
      )}
      style={{width: imgWidth && !size ? imgWidth : 32, height: imgHeight && !size ? imgHeight : 32}}
      key={key}
    >
      {(!img && !imgOnError) || error ? (
        <span
          className={classnames("avatar-content", {
            "position-relative": badgeUp
          })}
          style={contentStyles}
        >
          {initials ? getInitials(content) : content}
          {icon ? icon : null}
          {badgeUp ? (
            <Badge color={badgeColor ? badgeColor : "primary"} className="badge-sm badge-up" pill>
              {badgeText ? badgeText : "0"}
            </Badge>
          ) : null}
        </span>
      ) : (
        withPreview ? 
          <PhotoProvider>
            <PhotoView src={img}> 
              {/* we can't make img as compnents because photoview need wrpaed element not a dom (component) */}
              <img 
                className={classnames({
                  [imgClassName]: imgClassName
                })}
                src={img ?? imgOnError}
                alt="LOGO"
                height={imgHeight && !size ? imgHeight : 32}
                width={imgWidth && !size ? imgWidth : 32}
                onError={e => handleError(e)}
              />
            </PhotoView>
          </PhotoProvider>
          : 
          <img
            className={classnames({
              [imgClassName]: imgClassName
            })}
            src={img ?? imgOnError}
            alt="LOGO"
            height={imgHeight && !size ? imgHeight : 32}
            width={imgWidth && !size ? imgWidth : 32}
            onError={e => handleError(e)}
          />
      )}
      {status ? (
        <span
          className={classnames({
            [`avatar-status-${status}`]: status,
            [`avatar-status-${size}`]: size
          })}
        ></span>
      ) : null}
    </Tag>
  );
};

export default Avatar;